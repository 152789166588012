import type { MetaFunction } from "@remix-run/node";
import type {
  AggregateRating,
  BreadcrumbList,
  CollectionPage,
  FAQPage,
  Graph,
  LocalBusiness,
  Organization,
  Product,
  Review,
  WebPage,
  WebSite,
} from "schema-dts";
import { getLandingPageData } from "server/strapi.server";

const aggregateRating: AggregateRating = {
  "@type": "AggregateRating",
  ratingValue: "5",
  reviewCount: "34",
  bestRating: "5",
  worstRating: "1",
};

const getReviewItem = ({ author, body, rating }: ReviewItem): Review => {
  return {
    // "@context": "https://schema.org",
    "@type": "Review",
    author: {
      "@type": "Person",
      name: author,
      givenName: author.split(" ")[0],
      familyName: author.split(" ")[1],
    },
    reviewBody: body,

    reviewRating: {
      "@type": "Rating",
      ratingValue: rating,
      bestRating: 5,
      worstRating: 1,
    },
  };
};

type BreadCrumb = { name: string; url: string };
const generateBreadcrumbs = ({
  items,
  id,
}: {
  id: string;
  items: BreadCrumb[];
}): BreadcrumbList => {
  return {
    "@type": "BreadcrumbList",
    "@id": id,
    itemListElement: items.map((item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: item.name,
      item: item.url,
    })),
  };
};

type ReviewItem = { author: string; body: string; rating: number };
export const getReviewArray = () => {
  const users: ReviewItem[] = [
    {
      author: "Victor B",
      body: "J'ai fait appelle à GoodCollect pour mon entreprise, ils mon aidé dans le traitement de mes déchets mais également dans la stratégie et le conseille afin d'être plus efficace et surtout faire des économies. Je les recommande amplement!",
      rating: 5,
    },
    {
      author: "Sébastien Laurent",
      body: "Entreprise au top, je recommande ! C'était un vrai chantier pour évacuer les déchets de ma maison lors de la rénovation, quasiment impossible de trouver une benne à un prix abordable. Grâce à good collect, nous avons trouvé ce que nous recherchions et en un temps record, très réactif et serviable je recommande !",
      rating: 5,
    },
    {
      author: "Cloé Clavel Morsain",
      body: "Super pratique ! Dans mon restaurant, grâce a GoodCollect j'ai pu faire évacuer tous les encombrants très rapidement après le changement de mobilier. Rapide et efficace",
      rating: 4,
    },
    {
      author: "Antoine Pourchet",
      body: "En pleine rénovation de ma résidence secondaire, j'ai pu louer une benne en toute simplicité pour les gravas au meilleur prix ! ça de moins à penser ! Au top !",
      rating: 5,
    },
    {
      author: "Landre Lysiane",
      body: "Une belle réactivité pour le projet de désencombrer ma cour des gravas faisant suite à travaux et j ai pris connaissance des différents points de collecte autour de chez moi ! C est très pratique merci👍",
      rating: 5,
    },
    {
      author: "carla b",
      body: "Meilleur service de location de bennes à déchets du marché! Simple, efficace et pas trop cher. Je recommande!",
      rating: 5,
    },
    {
      author: "Aurore Legentil",
      body: "Le service GoodCollect est rapide et facile à utiliser, en plus d'une approche brillante qui profite à toutes les parties impliquées. Je recommande !",
      rating: 5,
    },
    {
      author: "Adeline Boris",
      body: "Excellente idée que d'aider les particuliers à gérer au mieux leurs déchets. Un frein aux envies de rénovation enfin levé, BRAVO!",
      rating: 5,
    },
    {
      author: "Olivier Bouly",
      body: "Que de tracas lors de l'organisation de notre guinguette annuelle. Il fallait évacuer les déchets recyclables et non-recyclables. Pas évident avec cette quantité. Avec GoodCollect c'est devenu super facile; disponibilité, clarté dans la démarche. Je recommande fortement !",
      rating: 5,
    },
    {
      author: "clara desport",
      body: "Service professionnel, bon contact, à l'écoute.",
      rating: 5,
    },
    {
      author: "Benoit Filippin",
      body: "Suite à des travaux d'intérieur j'ai contacté leur service pour une location de benne. Simple et efficace, je recommande",
      rating: 5,
    },
    {
      author: "Thierry OLLIVIER",
      body: "Pleinement satisfait de la prestation, je vous recommande sincèrement de vous rapprocher de la société GOODCOLLECT",
      rating: 5,
    },
    {
      author: "Hugo Roussel",
      body: "Excellente expérience avec Good Collect. Interface fluide et service client très réactif. Je recommande!",
      rating: 5,
    },
    {
      author: "Jean Daniel Colleman",
      body: "Entreprise au top, réactive, respect des délais",
      rating: 5,
    },
    {
      author: "Hippolyte Lepicard",
      body: "Service super niquel! Je recommande, pour tous type de chantier!",
      rating: 5,
    },
    {
      author: "Augustin Levoir",
      body: "J'adore ! Plateforme innovante qui offre un service de très grande qualité",
      rating: 5,
    },
    {
      author: "Sarah McBride",
      body: "Super service, merci pour leur réactivité!",
      rating: 5,
    },
    {
      author: "Emile Bachelier",
      body: "Rapide, simple & efficace !! Merci pour le service rendu",
      rating: 5,
    },
    {
      author: "Julie Boris",
      body: "Super efficaces et réactifs ! Je recommande !",
      rating: 5,
    },
    {
      author: "Maud Bouyala",
      body: "Service rapide, efficace et pas cher!",
      rating: 5,
    },
    {
      author: "didier schouten",
      body: "simple, efficace et rapide",
      rating: 5,
    },
  ];
  return users.map((user) => getReviewItem(user));
};

export const FRONTEND_URL = "https://goodcollect.co";
type JsonldData = {
  name: string;
  addressLocality: string;
  postalCode: string;
  url: string;
};
export const generateMetatags = ({
  title = "GoodCollect | Solution de collecte et valorisation de vos déchets",
  description = `Evacuez vos déchets c'est facile avec GoodCollect ! Réservez un service complet de location de matériel, de collecte et de valorisation des déchets`,
  image = "https://goodcollect-strapi.s3.eu-west-3.amazonaws.com/meta_goodcollect_seo_dc21e49b08.jpg",
  url = FRONTEND_URL,
  jsonLd = {
    name: "GoodCollect | Solution de collecte et valorisation de vos déchets",
    addressLocality: "Arles",
    postalCode: "13200",
    url: FRONTEND_URL,
  },
  breadcrumbs = null,
  searchFields = null,
  displayProduct = false,
  noIndex = false,
  displayWebsite = false,
  displayFaq = false,
  faqItems = [],
  pageUrl = "https://goodcollect.co",
}: {
  title?: string;
  noIndex?: boolean;
  description?: string;
  image?: string;
  url?: string;
  jsonLd?: JsonldData;
  breadcrumbs?: { id: string; items: BreadCrumb[] } | null;
  searchFields?: { search: string; category: string } | null;
  displayProduct?: boolean;
  displayWebsite?: boolean;
  displayFaq?: boolean;
  faqItems?: Awaited<ReturnType<typeof getLandingPageData>>["faqItems"];
  pageUrl: string;
}) => {
  if (!image) {
    image =
      "https://goodcollect-strapi.s3.eu-west-3.amazonaws.com/meta_goodcollect_seo_dc21e49b08.jpg";
  }

  // add review and aggregate Rating to the first element of jsonLd array if it doesn't have it and is Local Business

  const metaTags: ReturnType<MetaFunction> = [
    ...(noIndex ? [{ robots: "noindex" }] : []),
    {
      charSet: "utf-8",
      title: title,
    },
    {
      name: "description",
      content: description,
    },
    { name: "og:type", content: "website" },
    { name: "og:title", content: title },
    { name: "og:description", content: description },
    { name: "og:image", content: image },
    { name: "og:url", content: url },
    {
      name: "twitter:card",
      content: image ? "summary_large_image" : "summary",
    },
    { name: "twitter:url", content: url },
    { name: "twitter:title", content: title },
    { name: "twitter:image", content: image },

    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@graph": [
          getLocalBusiness({
            jsonLd,
          }),
          ...(displayProduct
            ? [
                getProduct({
                  description:
                    "Bennes à déchets en location professionel ou particulier, livraison sur chantier livraison devis en ligne",
                  image,
                  name: "Location benne",
                  url,
                }),
              ]
            : []),
          ...(displayWebsite ? [getWebsite({ FRONTEND_URL })] : []),
          ...(displayFaq ? [getFaqPage({ FRONTEND_URL, faqItems })] : []),
          getOrganization({ FRONTEND_URL }),
          ...(breadcrumbs
            ? [
                generateBreadcrumbs({
                  items: breadcrumbs.items,
                  id: breadcrumbs.id,
                }),
              ]
            : []),
          ...(searchFields
            ? [
                getSearchResult({
                  category: searchFields.category,
                  search: searchFields.search,
                  FRONTEND_URL,
                }),
              ]
            : []),
          getWebPage({
            FRONTEND_URL,
            pageName: title,
            pageDescription: description,
            pageUrl,
          }),
        ],
      } as Graph,
    },
  ];
  return metaTags;
};

const getLocalBusiness = ({
  jsonLd,
}: {
  jsonLd: JsonldData;
}): LocalBusiness => {
  return {
    "@type": "LocalBusiness",
    name: jsonLd
      ? jsonLd.name
      : "GoodCollect | Solution de collecte et valorisation de vos déchets",
    description: `Evacuez vos déchets c'est facile avec GoodCollect ! Réservez un service complet de location de matériel, de collecte et de valorisation des déchets`,

    address: {
      "@type": "PostalAddress",
      ...(!jsonLd
        ? {
            streetAddress: "28 Boulevard Georges Clemenceau",
            addressLocality: "Arles",
            postalCode: "13200",
            addressCountry: "FR",
            addressRegion: "Provence-Alpes-Côte d'Azur",
          }
        : {
            addressLocality: jsonLd.addressLocality,
            postalCode: jsonLd.postalCode,
            addressCountry: "FR",
          }),
    },
    openingHours: "Mo-Su",
    image:
      "https://goodcollect-strapi.s3.eu-west-3.amazonaws.com/Capture_d_ecran_2023_01_24_a_14_14_10_8abc7ef202.png",
    priceRange: "$$",
    paymentAccepted: "Cash, Credit Card, Cheque, Invoice",
    telephone: "(33) 6 48 96 73 67",
    url: "https://www.goodcollect.co",
    // aggregateRating,
    // review: getReviewArray(),
  };
};

const getWebsite = ({ FRONTEND_URL }: { FRONTEND_URL: string }): WebSite => {
  return {
    "@type": "WebSite",
    url: FRONTEND_URL,
    name: "GoodCollect",
    "@id": FRONTEND_URL,

    description: `Evacuez vos déchets c'est facile avec GoodCollect ! Réservez un service complet de location de matériel, de collecte et de valorisation des déchets`,
    publisher: {
      "@id": FRONTEND_URL,
    },
    potentialAction: [
      {
        "@type": "SearchAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: `${FRONTEND_URL}/blog?search={search_term_string}`,
        },

        // @ts-expect-error
        "query-input": "required name=search_term_string",
      },
    ],
    inLanguage: "fr-FR",
  };
};

const getOrganization = ({
  FRONTEND_URL,
}: {
  FRONTEND_URL: string;
}): Organization => {
  return {
    "@type": "Organization",
    "@id": FRONTEND_URL,
    name: "GoodCollect",

    logo: {
      "@type": "ImageObject",
      inLanguage: "fr-FR",
      url: "https://goodcollect-strapi.s3.eu-west-3.amazonaws.com/meta_goodcollect_seo_dc21e49b08.jpg",
      width: "1200",
      height: "600",
      contentUrl:
        "https://goodcollect-strapi.s3.eu-west-3.amazonaws.com/meta_goodcollect_seo_dc21e49b08.jpg",
      caption: "GoodCollect",
      "@id": `${FRONTEND_URL}#image`,
      sameAs: [
        "https://www.facebook.com/profile.php?id=100076957843606",
        "https://www.instagram.com/goodcollect",
        "https://twitter.com/Goodcollectco",
        "https://www.linkedin.com/company/goodcollect/",
      ],
      image: {
        "@id": `${FRONTEND_URL}#image`,
      },
    },
  };
};

const getSearchResult = ({
  category,
  search,
  FRONTEND_URL,
}: {
  search: string;
  category: string;
  FRONTEND_URL: string;
}): CollectionPage => {
  return {
    // @ts-expect-error
    "@type": ["CollectionPage", "SearchResultsPage"],
    "@id": `${FRONTEND_URL}/blog?search=${search}&category=${category}`,
    url: `${FRONTEND_URL}/blog?search=${search}&category=${category}`,
    name: `Vous avez cherché ${search} dans la catégorie ${category} | GoodCollect`,
    isPartOf: {
      "@id": FRONTEND_URL,
    },
    inLanguage: "fr-FR",
  };
};

const getProduct = ({
  name,
  description,
  image,
  url,
  // price,
}: {
  name: string;
  description: string;
  image: string;
  url: string;
  // price: string;
}): Product => {
  return {
    "@type": "Product",
    name,
    description,
    image,
    url,
    offers: {
      "@type": "Offer",
      price: "0",
      // two weeks from now
      // priceValidUntil: new Date(Date.now() + 12096e5).toISOString(),
      priceCurrency: "EUR",
      url,
      itemCondition: "https://schema.org/UsedCondition",
      availability: "https://schema.org/InStock",
      seller: {
        "@type": "Organization",
        name: "GoodCollect",
      },
      // shippingDetails: {
      // 	'@type': 'OfferShippingDetails',
      // 	shippingDestination: {
      // 		'@type': 'DefinedRegion',
      // 		name: 'FR',
      // 		addressCountry: 'FR',
      // 	},
      // 	shippingLabel: 'Livraison gratuite',
      // 	shippingRate: {
      // 		'@type': 'MonetaryAmount',
      // 		currency: 'EUR',
      // 		value: '0',
      // 	},
      // 	deliveryTime: {
      // 		'@type': 'ShippingDeliveryTime',
      // 		minimumDeliveryTime: 'P3D',
      // 		maximumDeliveryTime: 'P7D',
      // 		transitTimeLabel: '3 à 7 jours ouvrés',
      // 		handlingTime: {
      // 			'@type': 'QuantitativeValue',
      // 			value: 1,
      // 			unitCode: 'day',
      // 		},
      // 	} as ShippingDeliveryTime,
      // },
      // hasMerchantReturnPolicy: {
      // 	'@type': 'MerchantReturnPolicy',
      // 	'@id': `${url}/cgv`,
      // 	name: 'Conditions Générales de Vente',
      // 	url: `${url}/cgv`,
      // 	applicableCountry: 'FR',
      // 	returnPolicyCategory: {
      // 		'@type': 'MerchantReturnEnumeration',
      // 		name: 'Avant la livraison',
      // 	},
      // } as MerchantReturnPolicy,
    },
    aggregateRating: aggregateRating,
    review: getReviewArray(),
  };
};

const getFaqPage = ({
  FRONTEND_URL,
  faqItems,
}: {
  FRONTEND_URL: string;
  faqItems: Awaited<ReturnType<typeof getLandingPageData>>["faqItems"];
}): FAQPage => {
  return {
    "@type": "FAQPage",
    "@id": `${FRONTEND_URL}/faq`,
    url: `${FRONTEND_URL}/faq`,
    name: "Questions fréquentes",
    mainEntity: faqItems.map((item) => ({
      "@type": "Question",
      name: item.title,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.descriptionAsText,
      },
    })),
  };
};

const getWebPage = ({
  FRONTEND_URL,
  pageName,
  pageDescription,
  pageUrl,
}: {
  FRONTEND_URL: string;
  pageName: string;
  pageDescription: string;
  pageUrl: string;
}): WebPage => {
  pageUrl = `${FRONTEND_URL}${pageUrl}`;
  return {
    "@type": "WebPage",
    "@id": `${pageUrl}#webpage`,
    url: pageUrl,
    name: pageName,
    isPartOf: {
      "@id": `${FRONTEND_URL}#website`,
    },
    description: pageDescription,
    inLanguage: "fr-FR",
    datePublished: new Date().toISOString(),
    dateModified: new Date().toISOString(),
    potentialAction: [
      {
        "@type": "ReadAction",
        target: [pageUrl],
      },
    ],
  };
};
